import { regionNames } from "../../utilities";

import { pestsAndPathogensSharedStateDefinitions } from "./pests-and-pathogens/info";

const sharedStateDefinitions = {
  'regionSelector': {
    options: regionNames,
    initValue: regionNames,
    props: {
      listTypeSingular: 'Region',
      listTypePlural: 'Regions',
    }
  },
  'departureSelector': {
    options: ['Last Year', 'Normal'],
    initValue: 'Last Year'
  },
  ...pestsAndPathogensSharedStateDefinitions,
};

export default sharedStateDefinitions;
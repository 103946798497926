import { format } from 'date-fns';

import { calcInitStartDate, TODAY } from '../../../utilities';
import { TAB_NAME, updateOverlay, updateChartData, updateTitle, getInitTemp } from './fns';
import { whitePineNeedleDamageOptions, pestsAndPathogensAnnualOptions, pestsAndPathogensTimeseriesOptions, southernPineBeetlePastYearsOptions } from './charts';

const info = {
  name: TAB_NAME,
  subTabs: {
    'Case Studies': [{
      name: 'White Pine Needle Damage'
    },{
      name: 'Southern Pine Beetle'
    },{
      name: 'Pine Wood Nematode'
    },{
      name: 'Heterobasidion Root Disease'
    }]
  },
  chartInfo: {
    chartSelectorId: 'pestsAndPathogensChartSelector',
    charts: [{
          name: 'Total Precipitation in May-July Compared to Normal',
          showIfSubTab: ['White Pine Needle Damage'],
          func: (chartData, address, ...rest) => whitePineNeedleDamageOptions(chartData, 'Total', 'Total Precipitation in May-July Compared to Normal (1952-Present)', address)
        },{
          name: 'Number of Days with Precipitation in May-July Compared to Normal',
          showIfSubTab: ['White Pine Needle Damage'],
          func: (chartData, address, ...rest) => whitePineNeedleDamageOptions(chartData, 'Count', `Number of Days with Precipitation ≥${chartData.count.threshold} in May-July Compared to Normal (1952-Present)`, address)
        },{
          name: 'Cold Temperature Exceedance',
          showIfSubTab: ['Southern Pine Beetle', 'Pine Wood Nematode', 'Heterobasidion Root Disease'],
          func: (chartData, address, tabInfoContextState, selectedSubTab) => {
            const reformattedName = selectedSubTab.split(' ').map(p => p.toLowerCase()).join('-') + '-threshold-selector';
            const tab = tabInfoContextState.tabInformation.find(({ name }) => name === TAB_NAME);
            const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
            const thresholdValue = subTabChartOptions.find(({ id }) => id === reformattedName).props.value;
            let threshold = typeof thresholdValue === 'function' ? thresholdValue({ selectedSubTab })[0] : thresholdValue[0];
            return pestsAndPathogensTimeseriesOptions(chartData, address, threshold, 'below');
          }
        },{
          name: 'Occurrences in Season',
          showIfSubTab: ['Southern Pine Beetle', 'Pine Wood Nematode', 'Heterobasidion Root Disease'],
          func: (chartData, address, tabInfoContextState, ...rest) => pestsAndPathogensAnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.pestsAndPathogensSeasonBoundsSelector.value)
        },{
          name: 'Years with No Occurrences in Past 10 Seasons',
          showIfSubTab: ['Southern Pine Beetle'],
          func: (chartData, address, tabInfoContextState, ...rest) => southernPineBeetlePastYearsOptions(chartData, address, tabInfoContextState.tabsSharedState.pestsAndPathogensSeasonBoundsSelector.value)
        }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: updateOverlay,
    showLoading: true
  },{
    whenXChanges: ['pestsAndPathogensThresholdSelector', 'pestsAndPathogensSeasonBoundsSelector', 'rawChartData', 'selectedSubTab','white-pine-needle-damage-threshold-selector','southern-pine-beetle-threshold-selector','pine-wood-nematode-threshold-selector','heterobasidion-root-disease-threshold-selector'],
    handleChange: updateChartData,
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'pests-and-pathogens-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'pests-and-pathogens-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'pests-and-pathogens-charts',
      useSharedState: 'pestsAndPathogensChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'white-pine-needle-damage-threshold-selector',
      showIfSubTab: ['White Pine Needle Damage'],
      props: {
        columnHeading: 'Precipitation',
        minNum: 1,
        maxNum: 1,
        minValue: 0,
        maxValue: 100,
        initValue: 0.1,
        resetValue: 0.1,
        step: 0.1,
        colors: ['black'],
        label: () => 'Change Precipitation Threshold',
        showReset: true
      }
    },{
      component: 'thresholdSelector',
      id: 'southern-pine-beetle-threshold-selector',
      showIfSubTab: ['Southern Pine Beetle'],
      props: {
        columnHeading: 'Days Under',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        initValue: getInitTemp('Southern Pine Beetle'),
        resetValue: getInitTemp('Southern Pine Beetle'),
        step: 1,
        colors: ['black'],
        label: () => 'Change Temperature Threshold',
        showReset: true
      }
    },{
      component: 'thresholdSelector',
      id: 'pine-wood-nematode-threshold-selector',
      showIfSubTab: ['Pine Wood Nematode'],
      props: {
        columnHeading: 'Days Under',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        initValue: getInitTemp('Pine Wood Nematode'),
        resetValue: getInitTemp('Pine Wood Nematode'),
        step: 1,
        colors: ['black'],
        label: () => 'Change Temperature Threshold',
        showReset: true
      }
    },{
      component: 'thresholdSelector',
      id: 'heterobasidion-root-disease-threshold-selector',
      showIfSubTab: ['Heterobasidion Root Disease'],
      props: {
        columnHeading: 'Days Under',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        initValue: getInitTemp('Heterobasidion Root Disease'),
        resetValue: getInitTemp('Heterobasidion Root Disease'),
        step: 1,
        colors: ['black'],
        label: () => 'Change Temperature Threshold',
        showReset: true
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'pests-and-pathogens-season-bounds-selector',
      useSharedState: 'pestsAndPathogensSeasonBoundsSelector',
      showIfSubTab: ['Southern Pine Beetle', 'Pine Wood Nematode', 'Heterobasidion Root Disease'],
    }]
  },
  componentFns: {
    title: updateTitle
  },
  textPanelInfo: []
};
export default info;

export const pestsAndPathogensSharedStateDefinitions = {
  'pestsAndPathogensChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'pestsAndPathogensSeasonBoundsSelector': {
    persist: false,
    initValue: [calcInitStartDate('09-01', TODAY), format(TODAY, 'yyyy-MM-dd')]
  }
};
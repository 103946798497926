const COLORS = {
  lightRed: '#f47777',
  lightRedRgb: '244, 119, 119',
  darkRed: '#C42020',
  darkRedRgb: '212, 89, 89',
  lightGreen: '#28e244',
  green: '#16aa2e',
  greenRgb: '52, 150, 45',
  darkGreen: '#0e6d1c',
  darkGreenRgb: '14, 109, 28',
  lightBlue: '#6da9f7',
  darkBlue: '#436d96',
  lightGray: '#adadad',
  darkGray: '#6c6c6c',
  yellow: '#e0e05c',
  yellowRgb: '224, 224, 92',
  orange: '#e2ae1f',
  darkOrangeRgb: '224, 140, 31',
  thresholds: ['#bae4b3', '#74c476', '#238b45','#2c7745', '#275436']
};

export default COLORS;
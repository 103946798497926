import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../../components/tooltip-season/tooltip-season.component';
import { COLORS, isPastThreshold } from '../../../utilities';

function getWPNDColor(value) {
  if (value <= 0) {
    return COLORS.green;
  } else if (value <= 1) {
    return COLORS.yellow;
  } else if (value <= 2) {
    return COLORS.orange;
  } else {
    return COLORS.darkRed;
  }
}

function createPlotObjects(value) {
  const bandsInfo = [{
    color: COLORS.darkGreenRgb,
    from: -100,
    to: 0,
    labelText: 'Damage Not Likely'
  },{
    color: COLORS.yellowRgb,
    from: 0,
    to: 1,
    labelText: 'Damage Possible'
  },{
    color: COLORS.darkOrangeRgb,
    from: 1,
    to: 2,
    labelText: 'Damage Likely'
  },{
    color: COLORS.darkRedRgb,
    from: 2,
    to: 100,
    labelText: 'Damage Very Likely'
  }];

  const plotBands = bandsInfo.map(({ color, to, from, labelText}) => ({
    color: `rgba(${color}, ${(from <= value && value <= to) ? 0.1 : 0})`,
    from,
    to,
    label: {
      text: labelText,
      verticalAlign: 'middle',
      align: 'left',
      x: 30,
      y: 3,
      style: {
        color: (from <= value && value <= to) ? 'black' : COLORS.darkGray
      }
    }
  }));

  const plotLines = bandsInfo.map(({ to }) => ({
    width: 1,
    value: to,
    color: COLORS.lightGray
  }));
  
  return { plotBands, plotLines};
}

export function whitePineNeedleDamageOptions(chartData, dataType, title, address) {
  const { stDevYearly, yearly, mean, stDev } = chartData[dataType.toLowerCase()];

  const yMinMaxPadding = 0.2;
  let max = -Infinity;
  let min = Infinity;
  const data = stDevYearly.map(value => {
    if (value < min) min = value;
    if (max < value) max = value;
    
    return {
      y: value,
      marker: {
        fillColor: getWPNDColor(value),
        lineColor: 'black',
        lineWidth: 1,
        radius: 3
      }
    }
  });
  const { plotBands, plotLines } = createPlotObjects(stDevYearly.slice(-1)[0]);

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: title,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data,
        name: 'Standard Deviation Timeseries',
        color: 'black'
        
      }
    ],
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
      lineWidth: 0
    },
    yAxis: [
      {
        min: min - yMinMaxPadding,
        max: max + yMinMaxPadding,
        startOnTick: false,
        endOnTick: false,
        gridLineWidth: 0,
        // minorTickInterval: 0.5,
        // minorTickWidth: 1,
        // minorTickLength: 0,
        // minorGridLineDashStyle: 'DashDot',
        tickInterval: 1,
        title: {
          text: 'Standard Deviation',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands,
        plotLines
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';
        const point = this.points[0];
        return renderToStaticMarkup(
          <div className='tooltip-content-container'>
            <TooltipSeason year={point.x} color={COLORS.darkRed} />

            <div>
              <div className='mc-season'>Normal ({chartData.years[0]}-{chartData.years[chartData.years.length - 1]})</div>
              <div className='wpnd-container'>
                <div className='wpnd-number'>{Math.round(mean * 100) / 100}</div>
                <div className='wpnd-units'>{dataType === 'Total' ? 'inches' : 'days'}</div>
              </div>
              <div className='wpnd-container'>
                <div className='wpnd-number'>{Math.round(stDev * 100) / 100}</div>
                <div className='wpnd-units'>{dataType === 'Total' ? 'inches' : 'days'} per SD</div>
              </div>
            </div>

            <div>
              <div className='mc-season'>Year {dataType}</div>
              <div className='wpnd-container'>
                <div className='wpnd-number'>{Math.round(yearly[point.point.index] * 100) / 100}</div>
                <div className='wpnd-units'>{dataType === 'Total' ? 'inches' : 'days'}</div>
              </div>
              <div className='wpnd-container'>
                <div className='wpnd-number'>{Math.round(point.y * 100) / 100}</div>
                <div className='wpnd-units'>SDs</div>
              </div>
            </div>
          </div>
        );
      },
    },
  };
}

export function southernPineBeetlePastYearsOptions(chartData, address, seasonBounds) {
  const { years, past10 } = chartData.yearlySeasons;

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: `No Occurrences in the Past 10 Seasons from ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: past10,
        step: 'center',
        marker: {
          enabled: false
        },
        zones: [{
          color: COLORS.lightBlue,
          value: 0.1
        },{
          color: COLORS.lightRed,
          value: 10
        }]
      }
    ],
    xAxis: {
      categories: years.slice(10),
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      }
    },
    yAxis: [
      {
        min: 0,
        max: 10,
        startOnTick: true,
        endOnTick: true,
        tickAmount: 2.5,
        gridLineWidth: 0,
        title: {
          text: 'Were Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
          formatter: function () {
            let label = '';
            switch (this.axis.defaultLabelFormatter.call(this)) {
              case '0':
                label = 'Yes';
                break;
              case '7.5':
                label = 'No';
                break;
              default:
                break;
            }
            return label;
          }
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';
        const date = this.points[0].x;
        const value = this.points[0].y;
        return renderToStaticMarkup(
          <div className='tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.lightBlue} />
            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                {value ? <div>No occurrences in the past 10 seasons</div> : ''}
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

export function pestsAndPathogensTimeseriesOptions(chartData, address, threshold, direction) {
  const compFn = isPastThreshold(threshold, direction);
  const timeseries = chartData.timeseries.current.map(temp => compFn(temp) ? temp : null);
  const dates = chartData.timeseries.dates.slice(0, timeseries.length).map(str => str.slice(5));

  let min = 0;
  let max = 0;
  let zones = [];
  if (direction === 'below') {
    min = Math.min(...timeseries, threshold - 5);
    max = threshold + 5;
    zones = [{
      value: threshold,
      color: COLORS.lightBlue
    },{
      color: COLORS.darkRed
    }];
  } else {
    min = threshold - 5;
    max = Math.max(...timeseries, threshold + 5);
    zones = [{
      color: COLORS.darkRed
    },{
      value: threshold,
      color: COLORS.lightBlue
    }];
  }

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    title: {
      text: `Low Temperatures Throughout Season`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        groupPadding: 0,
        pointPadding: 0,
        threshold,
        zones
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            value: threshold,
            label: {
              text: `${threshold}°F`,
              align: 'right',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const val = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='tooltip-content-container'>
            <TooltipSeason year={date} color={compFn(val) ? COLORS.lightBlue : COLORS.darkRed} />

            <div className='mc-container'>
              <div className='mc-number'>{Math.round(val * 100) / 100}</div>
              <div className='mc-units'>°F</div>
            </div>
          </div>
        );
      },
    },
  };
}

export function pestsAndPathogensAnnualOptions(chartData, address, seasonBounds) {
  const { years, toDate, toDateDate, fullSeason } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...fullSeason, ...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/');

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'At End of Season',
        color: COLORS.lightGray,
        opacity: 0.6,
        borderColor: COLORS.lightGray,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: COLORS.lightBlue,
        pointPadding: 0.125
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1)),
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];

        return renderToStaticMarkup(
          <div className='tooltip-content-container'>
            <TooltipSeason year={date} color={COLORS.lightBlue} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}
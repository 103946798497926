const APP_NAME = 'FOREST';

const REGIONAL_INFO = [{
  name: 'Midwest',
  // stateFips: ['27', '19', '29', '17', '55', '26', '18', '39'],
  // statePostalCodes: ['MN','WI','MI','IA','IL','IN','OH','MO'],
  bbox: [[-97.382813,35.871247],[-80.354004,49.496675]]
},{
  name: 'Northeast',
  // stateFips: ['23', '33', '50', '25', '09', '44', '36', '42', '34', '10', '24', '54', '11'],
  // statePostalCodes: ['ME','VT','NH','MA','RI','CT','NY','PA','MD','DE','WV','NJ','DC'],
  bbox: [[-82.749023,37.230328],[-66.752930,47.502359]]
},{
  name: 'Northern Plains',
  // stateFips: ['30', '38', '46', '56', '31', '08'],
  // statePostalCodes: ['CO','WY','MT','ND','SD','NE'],
  bbox: [[-116.191406,36.809285],[-95.031738,49.095452]]
},{
  name: 'Pacific Northwest',
  // stateFips: ['53', '41', '16'],
  // statePostalCodes: ['WA','OR','ID'],
  bbox: [[-125.046387,41.902277],[-110.852051,49.181703]]
},{
  name: 'Southeast',
  // stateFips: ['51', '21', '47', '37', '45', '13', '12', '01', '28', '05', '22'],
  // statePostalCodes: ['VA','NC','SC','GA','FL','KY','TN','AR','LA','MS','AL'],
  bbox: [[-94.812012,24.966140],[-75.607910,39.520992]]
},{
  name: 'Southern Plains',
  // stateFips: ['20', '40', '48'],
  // statePostalCodes: ['TX','OK','KS'],
  bbox: [[-107.028809,25.700938],[-93.317871,40.229218]]
},{
  name: 'Southwest',
  // stateFips: ['06', '32', '49', '04', '35'],
  // statePostalCodes: ['CA','NV','UT','AZ','NM'],
  bbox: [[-124.716797,31.240985],[-102.832031,42.081917]]
}];

const regionNames = REGIONAL_INFO.map(ri => ri.name);
const regionBBoxes = REGIONAL_INFO.reduce((acc,regObj) => {
  acc[regObj.name] = regObj.bbox;
  return acc;
}, {});

const STATE_IDS_TO_USPS = {
  '01': 'AL',
  '02': 'AK',
  '04': 'AZ',
  '05': 'AR',
  '06': 'CA',
  '08': 'CO',
  '09': 'CT',
  '10': 'DE',
  '11': 'DC',
  '12': 'FL',
  '13': 'GA',
  '15': 'HI',
  '16': 'ID',
  '17': 'IL',
  '18': 'IN',
  '19': 'IA',
  '20': 'KS',
  '21': 'KY',
  '22': 'LA',
  '23': 'ME',
  '24': 'MD',
  '25': 'MA',
  '26': 'MI',
  '27': 'MN',
  '28': 'MS',
  '29': 'MO',
  '30': 'MT',
  '31': 'NE',
  '32': 'NV',
  '33': 'NH',
  '34': 'NJ',
  '35': 'NM',
  '36': 'NY',
  '37': 'NC',
  '38': 'ND',
  '39': 'OH',
  '40': 'OK',
  '41': 'OR',
  '42': 'PA',
  '72': 'PR',
  '44': 'RI',
  '45': 'SC',
  '46': 'SD',
  '47': 'TN',
  '48': 'TX',
  '49': 'UT',
  '50': 'VT',
  '51': 'VA',
  '78': 'VI',
  '53': 'WA',
  '54': 'WV',
  '55': 'WI',
  '56': 'WY'
};
const stateFips = Object.keys(STATE_IDS_TO_USPS);

const stateFipsToUsps = (stateId) => {
  return STATE_IDS_TO_USPS[String(stateId)];
}

// If first visit or no locations have been selected, use this address
const DEFAULT_LOCATION = {
  address: 'Ithaca, Tompkins County, New York',
  id: 'default',
  lat: 42.457975,
  lng: -76.46754,
};

const TODAY = new Date();

export {
  APP_NAME as appName,
  regionNames,
  regionBBoxes,
  stateFips,
  stateFipsToUsps,
  DEFAULT_LOCATION as defaultLocation,
  TODAY
};
import { format, parseISO, isBefore } from "date-fns";

import { TODAY, updateOverlayFromR2, isInSeason, isPastThreshold, calcSeasonYear } from "../../../utilities";

export const TAB_NAME = 'Pests and Pathogens';

export function getInitTemp(subTab) {
  let newValue = null;
  if (subTab === 'Southern Pine Beetle') {
    newValue = 14;
  } else if (subTab === 'Pine Wood Nematode') {
    newValue = 5;
  } else if (subTab === 'Heterobasidion Root Disease') {
    newValue = -13;
  }
  return newValue;
}

function getMeanAndSD(nums, isPopulation = true) {
  const len = nums.length;
  if (len < 1) return { stDev: null, mean: null };
  const mean = nums.reduce((sum, value) => sum + value) / len;
  const variance = nums.reduce((sum, value) => sum + (value - mean) ** 2, 0) / (len - (isPopulation ? 0 : 1));
  return { stDev: Math.sqrt(variance), mean};
}

function getSeasonCountsAndTotals(pcpns, startIdx, endIdx, threshold) {
  const seasonData = pcpns.slice(startIdx, endIdx);
  let count = 0;
  let total = 0;
  for (let j = 0; j < seasonData.length; j++) {
    const dayPcpn = seasonData[j][1];
    total += dayPcpn;
    if (dayPcpn >= threshold) {
      count += 1;
    }
  }
  return { count, total }
}

function calcWhitePineNeedleDamageChartData(pcpns, threshold) {
  const newChartData = {
    years: [],
    total: {
      yearly: [],
      mean: null,
      stDev: null,
      stDevYearly: []
    },
    count: {
      threshold,
      yearly: [],
      mean: null,
      stDev: null,
      stDevYearly: []
    }
  };

  const seasonStartDay = '05-01';
  const seasonEndDay = '07-31';
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  for (let i = 0; i < pcpns.length; i++) {
    const strDate = pcpns[i][0];

    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      newChartData.years.push(parseInt(strDate.slice(0,4)) + 1);
      const { count, total } = getSeasonCountsAndTotals(pcpns, idxYearStart, i + 1, threshold);
      newChartData.count.yearly.push(count);
      newChartData.total.yearly.push(total);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const { count, total } = getSeasonCountsAndTotals(pcpns, idxYearStart, pcpns.length, threshold);
    newChartData.count.yearly.push(count);
    newChartData.total.yearly.push(total);
    const seasonYear = calcSeasonYear(pcpns[pcpns.length - 1][0], `2025-${seasonStartDay}`, `2025-${seasonEndDay}`) + 1;
    newChartData.years.push(seasonYear);
  }

  ['total', 'count'].forEach(k => {
    const { mean, stDev } = getMeanAndSD(newChartData[k].yearly);
    newChartData[k].mean = mean;
    newChartData[k].stDev = stDev;
    newChartData[k].stDevYearly = newChartData[k].yearly.map(value => (value - mean) / stDev);
  });
  
  return newChartData;
}

const calcChartData = (temps, dataIdx, thresholds, seasonBounds, direction, TODAY) => {
  const compFn = isPastThreshold(thresholds, direction);
  const newChartData = {
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  const selectedSeasonIndices = [null, null];
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (strDate === seasonBounds[0]) selectedSeasonIndices[0] = i;
    if (strDate === seasonBounds[1]) selectedSeasonIndices[1] = i;

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      const seasonYear = calcSeasonYear(strDate, seasonBounds[0], seasonBounds[1], false);
      newChartData.yearlySeasons.years.push(seasonYear);
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = calcSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1], false);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
  }

  const selectedSeasonData = temps.slice(selectedSeasonIndices[0], selectedSeasonIndices[1] === null ? temps.length : selectedSeasonIndices[1] + 1);
  newChartData.timeseries = selectedSeasonData.reduce((acc, dayData) => {
    acc.dates.push(dayData[0]);
    acc.current.push(dayData[dataIdx]);
    return acc;
  }, { dates: [], current: [] });

  return newChartData;
};

export function updateChartData({ tabInformation, selectedTab, selectedSubTab, selectedLocation, tabsSharedState, rawChartData }, _, { setChartData }) {
  if (
    selectedTab === TAB_NAME &&
    selectedLocation in rawChartData && 'mintMaxt' in rawChartData[selectedLocation] && 'pcpn' in rawChartData[selectedLocation]
  ) {
    let seasonBounds = tabsSharedState.pestsAndPathogensSeasonBoundsSelector.value;
    if (typeof seasonBounds === 'function') seasonBounds = seasonBounds();
    
    const reformattedName = selectedSubTab.split(' ').map(p => p.toLowerCase()).join('-') + '-threshold-selector';
    const tab = tabInformation.find(({ name }) => name === TAB_NAME);
    const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
    const threshold = subTabChartOptions.find(({ id }) => id === reformattedName).props.value;
    
    let newChartData;
    if (selectedSubTab === 'White Pine Needle Damage') {
      newChartData = calcWhitePineNeedleDamageChartData(rawChartData[selectedLocation]['pcpn'], threshold[0]);
    } else {
      newChartData = calcChartData(rawChartData[selectedLocation]['mintMaxt'], 1, threshold, seasonBounds, 'below', TODAY);
    }

    if (selectedSubTab === 'Southern Pine Beetle') {
      // Find years where previous 10 seasonss had no occurrences
      const { fullSeason } = newChartData.yearlySeasons;
      const spbExtraData = [];
      const windowLength = 10;
      const valuesWindow = [];
      for (let i = 0; i < fullSeason.length; i++) {
        valuesWindow.push(fullSeason[i]);
        if (valuesWindow.length === windowLength) {
          spbExtraData.push(valuesWindow.reduce((a,b) => a+b, 0) === 0 ? 7.5 : 0);
          valuesWindow.shift();
        }
      }
      newChartData.yearlySeasons.past10 = spbExtraData;
    }

    return () => setChartData(newChartData);
  }
  return () => {};
}

export function updateOverlay({ selectedSubTab }, _, { updateOverlayData }) {
  const reformattedName = selectedSubTab.split(' ').map(p => p.toLowerCase()).join('_');
  const jsonFileName = `${reformattedName}.json`;
  const jsonFileDataKey = 'departures';
  return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
    .then(d => () => updateOverlayData(d));
}

export function updateTitle({selectedSubTab, display, overlayDataDate, chartData}) {
  try {
    let sdate, edate;
    if (selectedSubTab === 'White Pine Needle Damage') {
      if (display === 'map') {
        const dateObj = parseISO(overlayDataDate);
        sdate = format(new Date(dateObj.getFullYear(), 4, 1), 'LLL do, yyyy');
        edate = format(parseISO(overlayDataDate), 'LLL do, yyyy');
      } else {
        sdate = chartData.years[0];
        edate = chartData.years[chartData.years.length - 1];
      }
    } else {
      if (display === 'map') {
        const dateObj = parseISO(overlayDataDate);
        const isCurrentSeasonYear = isBefore(dateObj, new Date(dateObj.getFullYear(), 8, 1));
        const syear = dateObj.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
        sdate = format(new Date(syear, 8, 1), 'LLL do, yyyy');
        edate = format(dateObj, 'LLL do, yyyy');
      } else {
        sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
        edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
      }
    }

    const type = selectedSubTab;
    return {
      type,
      sdate,
      edate
    }
  } catch {
    return null;
  }
}